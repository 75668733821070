import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { getData, putData } from "../../FetchService";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

export default function ViewCorporateEventDetailsFree() {
  const location = useLocation();
  const navigate = useNavigate();
  let id = location.state.data._id;
  const [data, setData] = useState([]);
  const [show, setShow] = useState("");
  const [teams, setTeams] = useState([]);
  const [winnerTeam, setWinnnerTeam] = useState({});

  const [invitedUser, setInviteUser] = useState([]);

  const textChange = (id) => {
    if (show == id) {
      setShow("");
    } else {
      setShow(id);
    }
  };

  const getCorporateEventDetails = async () => {
    const res = await getData(`/corporate-event-details?id=${id}`);
    setData(res?.data);
    setTeams(res?.data?.teams);
    const fill = res?.data?.teams?.filter((el) => el?._id == res?.data?.winner);
    setWinnnerTeam(fill);
  };
  let totalDistance = 0;

  teams?.map((el) => {
    return (totalDistance += el.activities.distance);
  });
  useEffect(() => {
    getCorporateEventDetails();
  }, []);
  const handleOneTeam = async (id) => {
    const res = await getData(`team-details?id=${id}`);
    res?.data?.map((el) => setInviteUser(el.invitedUsers));
  };
  const dateConverter = (time) => {
    var date = new Date(null);
    date.setSeconds(time);
    var hhmmssFormat = date.toISOString().substr(11, 8);
    let hr = hhmmssFormat.split(":")[0];
    let min = hhmmssFormat.split(":")[1];
    let sec = hhmmssFormat.split(":")[2];

    return `${hr != "00" ? hr + "hr" : ""} ${min != "00" ? min + "min" : ""} ${
      sec != "00" ? sec + "sec" : ""
    }`;
  };

  const deleteSingleEvent = async (el) => {
    let data = {
      id: [el],
    };
    const res = await putData("delete-events", data);
    if (res.status) {
      setTimeout(() => {
        navigate("/managecorporateevents");
      }, 1000);
      toast.success(`${res.message} Successfully !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div>
      {/* <ToastContainer autoClose={1000} /> */}

      <div className="main mt-8">
        <div className="content container-fluid manage_user manage_event_list manageUpcomingFree">
          <div className="pageheader">
            <div className="row align-items-center mb-3 gap-3 gap-sm-0">
              <div className="col-sm d-flex align-items-center gap-2">
                <h1 className="page-header-title m-0">View Event Details</h1>
              </div>
              {data?.status == 1 ? (
                <div className="col-12 col-sm-auto">
                  <div className="gap-3 d-flex">
                    <button
                      className="btn btn-dark addEventBtn"
                      onClick={() =>
                        navigate("add-edit-corporate-event-deetails", {
                          state: { edit: true, data: data },
                        })
                      }
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-dark addEventBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#askdeletecategory"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger addEventBtn"
                      onClick={() => navigate("/managecorporateevents")}
                    >
                      Cancel Event
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="pageBody mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card p-3 pb-4">
                  <div className="row gap-3 gap-md-0">
                    <div className="col-md-7 col-lg-6 col-xl-7 col-xxl-6">
                      <div className="row gap-3 gap-sm-0">
                        <div className=" col-sm-4 col-md-5 col-lg-6">
                          <div className="imgOuter">
                            <img
                              className="w-100 h-100"
                              // src="assets/img/event-img-1.png"
                              src={data?.image}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-sm col-md-7 col-lg-6">
                          <div className="row">
                            <div className="col col-md-12">
                              <div className="eventDetail">{data?.name}</div>
                              <div className="smallTxt my-1 my-sm-2 eventDec">
                                {data?.template}
                              </div>
                              <div className="smallTxt my-1 my-sm-2 startDate">
                                Start Date:{" "}
                                {new Date(data.startDate).getUTCDate()}{" "}
                                {new Date(data.startDate).toLocaleString(
                                  "default",
                                  { month: "short" }
                                )}{" "}
                                {new Date(data.startDate).getFullYear()}
                              </div>
                              <div className="smallTxt my-1 my-sm-2 endDate">
                                End Date: {new Date(data.endDate).getUTCDate()}{" "}
                                {new Date(data.endDate).toLocaleString(
                                  "default",
                                  { month: "short" }
                                )}{" "}
                                {new Date(data.endDate).getFullYear()}
                              </div>
                            </div>
                            <div className="col-auto col-md-12 customCol">
                              <button
                                // style={{ backgroundColor: "red" }}
                                className="btn btn-secondary ps-pe-3 pt-1 pb-1  d-flex inline align-items-center justify-content-center block text-white"
                              >
                                FREE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-lg-6 col-xl-5 col-xxl-6">
                      <div className="diveder border-top d-md-none"></div>
                      <div className="row pt-3 pt-md-0 ">
                        <div className="col">
                          <ul className="p-0 m-0 list-unstyled">
                            <li className="mb-1 mb-sm-2">
                              <b>Qualifying Activities:</b>{" "}
                              <span>
                                {" "}
                                {data?.activities?.map((el) => {
                                  return el == 1
                                    ? "Running "
                                    : "" || el == 2
                                    ? "Cycling "
                                    : "" || el == 3
                                    ? "Jumping "
                                    : "" || el == 4
                                    ? "Wolking "
                                    : "" || el == 5
                                    ? "Skipping "
                                    : "";
                                })}
                              </span>
                            </li>
                            <li className="my-1 my-sm-2">
                              <b>Distance (KMs): {totalDistance}</b>{" "}
                            </li>
                          </ul>
                        </div>
                        <div className="col-auto customCol">
                          {data?.status == 1 ? (
                            <button className=" btn btn-primary h-50  d-flex inline align-items-center block text-white">
                              Upcomming
                            </button>
                          ) : data?.status == 2 ? (
                            <button className=" btn btn-warning h-50  d-flex inline align-items-center block text-white">
                              Ongoing
                            </button>
                          ) : data?.status === 3 ? (
                            <button className=" btn btn-success h-50  d-flex inline align-items-center block text-white">
                              Completed
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="">
                  <div className="sectionTitle text-black mb-3">
                    Description
                  </div>
                  <span className="smallTxt">{data?.description}</span>
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="col-sm-6 mb-sm-0 mb-3 w-50 pe-3">
                  <div className=" mb-3">
                    <div className="sectionTitle text-black">Event Admin</div>
                  </div>
                  <div className="card  p-3 ">
                    {data?.admin ? (
                      <div className=" d-flex  align-items-center  ">
                        <div className="col-auto">
                          <div
                            style={{ maxWidth: "35px", maxHeight: "40px" }}
                            className="adminImg rounded-pill overflow-hidden"
                          >
                            <img
                              style={{ maxWidth: "40px", maxHeight: "40px" }}
                              src={data?.admin?.image}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col ms-3">
                          <span className="smallTxt">{`${data?.admin?.firstName} ${data?.admin?.lastName}`}</span>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        <h3 className="text-danger">No admin !</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className=" mb-3">
                  <div className="sectionTitle text-black">
                    Event Achievements
                  </div>
                </div>
                <div className="card p-3 p-sm-4  gap-2">
                  <div className="text-black">
                    <b>Reward Points:</b> <span>{data?.points}</span>
                  </div>
                  <span className="smallTxt">{data?.rewardDesc}</span>
                </div>
              </div>
              {data?.status == 3 ? (
                <>
                  {winnerTeam?.map((el) => {
                    return (
                      <div key={el._id} className="col-12 mt-4">
                        <div className=" mb-3">
                          <div className="sectionTitle text-black">
                            Team Statistics
                          </div>
                        </div>
                        <div className="card p-3 gap-2">
                          <div className="text-black text-center">
                            <span>Winning Team</span>
                          </div>
                          <div className="text-center py-3">
                            <img src="/assets/img/trophy.png" alt="...." />
                          </div>
                          <div className="row justify-content-center">
                            <NavLink className="card card-hover-shadow h-100 col-sm-6 col-lg-5 mb-md-3 mb-lg-5">
                              <div className="card-body">
                                <div className="row align-items-center gx-2">
                                  <div className="col-12 mb-2">
                                    <h2 className="card-title text-center">
                                      {!!data.winner
                                        ? el?.name
                                        : "Not desided yet !"}
                                    </h2>
                                  </div>
                                  <div className="col-12 mb-2">
                                    <h6 className="card-subtitle text-center">
                                      Added Participants:{" "}
                                      {el?.invitedParticipants}
                                    </h6>
                                  </div>
                                  <div className="col-12 mb-3">
                                    {el?.leaderboard?.map((el, i) => {
                                      return (
                                        <h6
                                          key={i}
                                          className="card-subtitle text-center"
                                        >
                                          Event Admin:{" "}
                                          {`${el?.firstName} ${el?.lastName}`}
                                        </h6>
                                      );
                                    })}
                                  </div>
                                  <div className="col-12 mb-2">
                                    <h6 className="card-subtitle text-center">
                                      {`  Distance and Time Covered: ${
                                        el.activities?.distance / 1000
                                      }KMs, ${dateConverter(
                                        el?.activities?.time
                                      )}`}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="col-12 mt-4">
                    <div className="accordion" id="accordionExample">
                      {data?.teams?.map((el, i) => {
                        return (
                          <div
                            key={i}
                            className="accordion-item mb-4 shadow rounded-3 collapsed"
                          >
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                onClick={() => {
                                  handleOneTeam(el?._id);
                                  textChange(el?._id);
                                }}
                                className="accordion-button rounded-3"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#${el._id}`}
                                aria-expanded={
                                  show == el._id ? "true" : "false"
                                }
                              >
                                <div className="card shadow-none border-0 p-0 ">
                                  <div className="card-body p-sm-3 p-2">
                                    <div className="row align-items-center gx-2">
                                      <div className="col-12 mb-2">
                                        <h2 className="card-title text-inherit fs-3">
                                          {el.name} (20)
                                        </h2>
                                      </div>
                                      <div className="col-12 mb-2">
                                        <h6 className="card-subtitle">
                                          Event Admin:{" "}
                                          {`${el.admin?.firstName} ${el.admin?.lastName}`}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </h2>
                            <div
                              className={`accordion-collapse collapse${
                                show == el._id ? "show" : ""
                              }`}
                              aria-labelledby="headingOne"
                              data-bs-parent={`#accordionExample`}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-sm-6">
                                    {invitedUser.map((item, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="row align-items-center mb-3 border-bottom py-3 mx-0"
                                        >
                                          <div className="col-auto">
                                            <div className="icon_w border d-flex align-items-center justify-content-center rounded-pill">
                                              <i className="bi bi-person-fill"></i>
                                            </div>
                                          </div>
                                          <div className="col">
                                            <h6 className="mb-0 fs-5">
                                              {`${item.firstName} ${item.lastName}`}
                                            </h6>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-12 mt-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className=" mb-3">
                      <div className="sectionTitle text-black">Event Teams</div>
                    </div>

                    <div className="m-3">
                      {data?.teams?.length ? (
                        <button
                          type="button"
                          onClick={() =>
                            navigate("manage-corporate-teams", {
                              state: { id: data?._id },
                            })
                          }
                          className="btn btn-dark"
                        >
                          Manage Teams
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="card p-3 p-sm-4  gap-2">
                    <div className="row">
                      {data?.teams?.length ? (
                        data?.teams?.map((item, i) => {
                          return (
                            <div key={i} className="col-sm-6 mb-3">
                              <div className="card card-hover-shadow h-100">
                                <div className="card-body">
                                  <div className="row align-items-center gx-2">
                                    <div className="col-12 px-0 mb-3 row mx-0">
                                      <div className="col ps-0">
                                        <h2 className="card-title text-inherit">
                                          {item?.name}
                                        </h2>
                                      </div>
                                    </div>
                                    <div className="col-12 px-0 mb-3">
                                      <h6 className="card-subtitle">
                                        Added Participants:
                                        {item?.invitedParticipants}
                                      </h6>
                                    </div>
                                    <div className="col-12 px-0">
                                      <h6 className="card-subtitle">
                                        Event Admin:
                                        {`${item?.admin?.firstName} ${item?.admin?.lastName}`}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-black text-center">
                          <span>No Teams</span>
                          <div className="mt-2">
                            <button
                              type="button"
                              onClick={() =>
                                navigate("manage-corporate-teams", {
                                  state: { id: data?._id },
                                })
                              }
                              className="btn btn-dark"
                            >
                              Create Teams
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal for delete --> */}
      <div
        className="modal fade"
        id="askdeletecategory"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
              >
                Alert !
              </h5>
            </div>
            <div className="modal-body">
              Do you want to delete the category - <strong>{data?.name}</strong>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => deleteSingleEvent(data?._id)}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
